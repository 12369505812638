/* tslint:disable */
/* eslint-disable */
/**
 * VerySimpleFileHost
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ArchiveFormat,
    AuthenticationFailureDto,
    DirectoryDto,
} from '../models';

export interface ApiFilesDownloadManyFormPostRequest {
    archiveFormat: ArchiveFormat;
    asAttachment?: boolean;
    paths?: Array<string>;
}

export interface ApiFilesDownloadManyPostRequest {
    archiveFormat: ArchiveFormat;
    asAttachment?: boolean;
    requestBody?: Array<string>;
}

export interface ApiFilesDownloadPathGetRequest {
    path: string;
    archiveFormat?: ArchiveFormat;
    asAttachment?: boolean;
}

export interface ApiFilesListingPathGetRequest {
    path: string;
    depth?: number;
}

/**
 * 
 */
export class FilesApi extends runtime.BaseAPI {

    /**
     */
    async apiFilesDownloadManyFormPostRaw(requestParameters: ApiFilesDownloadManyFormPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.archiveFormat === null || requestParameters.archiveFormat === undefined) {
            throw new runtime.RequiredError('archiveFormat','Required parameter requestParameters.archiveFormat was null or undefined when calling apiFilesDownloadManyFormPost.');
        }

        const queryParameters: any = {};

        if (requestParameters.archiveFormat !== undefined) {
            queryParameters['archiveFormat'] = requestParameters.archiveFormat;
        }

        if (requestParameters.asAttachment !== undefined) {
            queryParameters['asAttachment'] = requestParameters.asAttachment;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.paths) {
            formParams.append('paths', requestParameters.paths.join(runtime.COLLECTION_FORMATS["csv"]));
        }

        const response = await this.request({
            path: `/api/Files/DownloadManyForm`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiFilesDownloadManyFormPost(requestParameters: ApiFilesDownloadManyFormPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiFilesDownloadManyFormPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiFilesDownloadManyPostRaw(requestParameters: ApiFilesDownloadManyPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.archiveFormat === null || requestParameters.archiveFormat === undefined) {
            throw new runtime.RequiredError('archiveFormat','Required parameter requestParameters.archiveFormat was null or undefined when calling apiFilesDownloadManyPost.');
        }

        const queryParameters: any = {};

        if (requestParameters.archiveFormat !== undefined) {
            queryParameters['archiveFormat'] = requestParameters.archiveFormat;
        }

        if (requestParameters.asAttachment !== undefined) {
            queryParameters['asAttachment'] = requestParameters.asAttachment;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/Files/DownloadMany`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiFilesDownloadManyPost(requestParameters: ApiFilesDownloadManyPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiFilesDownloadManyPostRaw(requestParameters, initOverrides);
    }

    /**
     * Download file/directory
     */
    async apiFilesDownloadPathGetRaw(requestParameters: ApiFilesDownloadPathGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.path === null || requestParameters.path === undefined) {
            throw new runtime.RequiredError('path','Required parameter requestParameters.path was null or undefined when calling apiFilesDownloadPathGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.archiveFormat !== undefined) {
            queryParameters['archiveFormat'] = requestParameters.archiveFormat;
        }

        if (requestParameters.asAttachment !== undefined) {
            queryParameters['asAttachment'] = requestParameters.asAttachment;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Files/Download/{path}`.replace(`{${"path"}}`, encodeURIComponent(String(requestParameters.path))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Download file/directory
     */
    async apiFilesDownloadPathGet(requestParameters: ApiFilesDownloadPathGetRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiFilesDownloadPathGetRaw(requestParameters, initOverrides);
    }

    /**
     * Retrieve file listing for path
     */
    async apiFilesListingPathGetRaw(requestParameters: ApiFilesListingPathGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<DirectoryDto>> {
        if (requestParameters.path === null || requestParameters.path === undefined) {
            throw new runtime.RequiredError('path','Required parameter requestParameters.path was null or undefined when calling apiFilesListingPathGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.depth !== undefined) {
            queryParameters['depth'] = requestParameters.depth;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Files/Listing/{path}`.replace(`{${"path"}}`, encodeURIComponent(String(requestParameters.path))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Retrieve file listing for path
     */
    async apiFilesListingPathGet(requestParameters: ApiFilesListingPathGetRequest, initOverrides?: RequestInit): Promise<DirectoryDto> {
        const response = await this.apiFilesListingPathGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
