/* tslint:disable */
/* eslint-disable */
/**
 * VerySimpleFileHost
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AuthenticationFailureDto,
    UserAddRequestDto,
    UserEditDto,
    UserListingDto,
    UserResponseDto,
} from '../models';

export interface ApiUsersAddUserPostRequest {
    userAddRequestDto?: UserAddRequestDto;
}

export interface ApiUsersDeleteUserUserIdDeleteRequest {
    userId: string;
}

export interface ApiUsersEditUserUserIdPutRequest {
    userId: string;
    userEditDto?: UserEditDto;
}

/**
 * 
 */
export class UsersApi extends runtime.BaseAPI {

    /**
     */
    async apiUsersAddUserPostRaw(requestParameters: ApiUsersAddUserPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UserResponseDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/Users/AddUser`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.userAddRequestDto,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async apiUsersAddUserPost(requestParameters: ApiUsersAddUserPostRequest, initOverrides?: RequestInit): Promise<UserResponseDto> {
        const response = await this.apiUsersAddUserPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiUsersDeleteUserUserIdDeleteRaw(requestParameters: ApiUsersDeleteUserUserIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling apiUsersDeleteUserUserIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Users/DeleteUser/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiUsersDeleteUserUserIdDelete(requestParameters: ApiUsersDeleteUserUserIdDeleteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiUsersDeleteUserUserIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiUsersEditUserUserIdPutRaw(requestParameters: ApiUsersEditUserUserIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UserResponseDto>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling apiUsersEditUserUserIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/Users/EditUser/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.userEditDto,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async apiUsersEditUserUserIdPut(requestParameters: ApiUsersEditUserUserIdPutRequest, initOverrides?: RequestInit): Promise<UserResponseDto> {
        const response = await this.apiUsersEditUserUserIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiUsersListUsersGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<UserListingDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Users/ListUsers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async apiUsersListUsersGet(initOverrides?: RequestInit): Promise<Array<UserListingDto>> {
        const response = await this.apiUsersListUsersGetRaw(initOverrides);
        return await response.value();
    }

}
